import TransitionLink from 'gatsby-plugin-transition-link';
import React, { useEffect, useContext, useState, useRef } from 'react';
import Meta from '../components/meta';
import { IRoutesPage } from '../types/heartcore.types';
import gsap from 'gsap';
import MorphSVGPlugin from '../libs/MorphSVGPlugin';
import ScrollToPlugin from '../libs/ScrollToPlugin';
import { transitionToSpecificRoute } from '../utils/animations/routes-map-transitions';
import RoutesMap from '../routes/routes-map';
import RoutesMapMobile from '../routes/routes-map-mobile';
import { PageData } from '../types/global.types';
import ModulesController from '../components/modules-controller';
import LokaltogContext from '../context/context';
import { timeout } from '../utils/utils';
import GoBackLink from '../components/go-back-link';
import { useContent } from '../hooks/use-content';
import { getCleanUrl } from '../utils/shared-build-runtime-utils';

export enum RouteLinesEnum { 
	Oestbanen = 'oestbanen',
	Toelloese = 'toelloese',
	Odsherred = 'odsherred',
	Lolland = 'lolland',
	Naerum = 'naerum',
	Frederiksvaerk = 'frederiksvaerk',
	Lillenord = 'lillenord',
	Gilleleje = 'gilleleje-hilleroed',
	Tisvildeleje = 'tisvildeleje-hilleroed',
	Hornbaekbanen = 'hornbaekbanen',
	NorthGroup = 'north-group'
}

const RoutesPage = ({ pageContext }: PageData<IRoutesPage>) => {
	const context = useContext(LokaltogContext);
	const { parent, headline, children: routes, modules, meta } = useContent<IRoutesPage>(pageContext, context.rootNodeUrl);
	const [ selectedRouteLine, setSelectedRouteLine ] = useState(null);
	const [ transitioning, setTransitioning ] = useState(false);
	const [ northGroupActive, setNorthGroupActive ] = useState(false);
	const contentContainerEl = useRef(null);
	const originSvgEl = useRef(null);
	const transitionLinkRef = useRef({});

	const isMobile = () => {
		return context.windowWidth < 768;
	};

	useEffect(() => {
		gsap.registerPlugin(MorphSVGPlugin);
		gsap.registerPlugin(ScrollToPlugin);
	}, []);

	const setActiveRouteLinkHandler = (line) => {
		if(northGroupActive) { setNorthGroupActive(false); }
		setSelectedRouteLine(line);
	};

	const mapClickHandler = (line) => {
		if(line !== RouteLinesEnum.NorthGroup) {
			setTransitioning(true);
			setActiveRouteLinkHandler(line);
		} else {
			setNorthGroupActive(true);
		}
	};

	const mobileTransitionToRouteHandler = (line) => {
		const promise = new Promise((resolve) => {
			gsap.to(window, {
				scrollTo: 0,
				ease: 'expo.out',
				duration: .6,
				onComplete: async () => {
					setSelectedRouteLine(line);
					await timeout(50);
					resolve(true);
				},
			});
		});
		return promise;
	};

	useEffect(() => {
		if(selectedRouteLine && transitioning) {
			setTransitioning(false);
			transitionLinkRef.current[selectedRouteLine].click();
		}
	}, [selectedRouteLine]);

	return (
		<div className='routes box-wide'>
			<Meta {...meta} />
			<div className={`routes__inner-wrapper ${selectedRouteLine ? 'selection-in-progress' : ''}`}>
				<div className='routes__go-back-link-wrapper fade-container'><GoBackLink parent={parent}></GoBackLink></div>
				{!isMobile() ?
					<RoutesMap svgRef={originSvgEl} selectedRouteId={selectedRouteLine} onRouteClick={mapClickHandler} northGroupActive={northGroupActive}/>
					:
					<RoutesMapMobile svgRef={originSvgEl} selectedRouteId={selectedRouteLine} onRouteClick={mapClickHandler} northGroupActive={northGroupActive}/>
				}
				<div className='routes__content-container' ref={contentContainerEl}>
					<h2>{headline}</h2>
					<ul className='routes__list-of-routes'>
						{routes?.map(routePage => 
							<li key={routePage.id}>
								<TransitionLink
									ref={(element) => transitionLinkRef.current[routePage.route.line] = element}
									to={getCleanUrl(routePage.url, context.rootNodeUrl)}
									onMouseEnter={!isMobile() ? () => setActiveRouteLinkHandler(routePage.route.line) : null}
									onMouseLeave={!isMobile() ? () => setActiveRouteLinkHandler(null) : null}
									className={`routes__single-route-trigger ${selectedRouteLine === routePage.route.line && 'is-active'}`}
									exit={{
										zIndex: 1,
										length: 2,
									}}
									entry={{
										zIndex: 0,
										length: 2,
										appearAfter: isMobile() ? .65 : 0,
									}}
									trigger={async pages => {
										if(isMobile()) {
											await mobileTransitionToRouteHandler(routePage.route.line);
										}
										const exit = await pages.exit;
										const entry = await pages.entry;

										entry.visible.then(() => {
											const routePageHeaderEl = entry.node.querySelector('.route__headline-wrapper');
											const destinationSvgEl = entry.node.querySelector('.routes__map-svg');
											gsap.from([entry.node.querySelectorAll('.fade-container'), entry.node.querySelector('.footer')], { opacity: 0, duration: .4});
											gsap.to([exit.node.querySelectorAll('.fade-container'), exit.node.querySelector('.footer')], { opacity: 0, duration: .4});
											transitionToSpecificRoute(contentContainerEl.current, routePageHeaderEl, originSvgEl.current, destinationSvgEl);
										});
										
										//const continueTransitioning = await transitionToSpecificRoute(contentContainerEl.current, routePageHeaderEl, originSvgEl.current, destinationSvgEl);
										//continueTransitioning();
										
									}}
								>
									<span className='font-semibold'>{routePage.route.number}&nbsp;</span>
									<span>{routePage.route.title}</span>
								</TransitionLink>
							</li>,
						)}
					</ul>
				</div>
			</div>
			<div className='fade-container'><ModulesController modules={modules} /></div>
		</div>
	);
};


export default RoutesPage;